import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, isSupported } from "firebase/messaging/sw";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_SERVER_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_SERVER_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_SERVER_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_SERVER_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_SERVER_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_SERVER_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_SERVER_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
let messaging;
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
isSupported().then((supported) => {
    if (supported) {
        messaging = getMessaging(app);
    }
});
const db = getFirestore();
const auth = getAuth();

// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LeB7wAqAAAAAAL9B7x1Ux86rOUygQtXq-YFL-al'),
    isTokenAutoRefreshEnabled: true,
});

export { app, auth, analytics, messaging, db };
