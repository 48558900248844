import PropTypes from 'prop-types';
// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| LEFT SIDEBAR MENU LIST ||============================== //

const MenuList = ({ tasksDisabled, shiftsDisabled, threadsDisabled }) => {
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} tasksDisabled={tasksDisabled} shiftsDisabled={shiftsDisabled} threadsDisabled={threadsDisabled} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

MenuList.proptypes = {
    tasksDisabled: PropTypes.bool,
    shiftsDisabled: PropTypes.bool,
    threadsDisabled: PropTypes.bool
};

export default MenuList;
